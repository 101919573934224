import React from 'react';

import NumberFormat from "react-number-format";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";

import DetailsIcon from "@material-ui/icons/Details";
import ChangeHistoryIcon from "@material-ui/icons/ChangeHistory";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import BlockIcon from "@material-ui/icons/Block";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import OrderTrackingList from "../TrackingList";

import { DateTime } from "luxon";

import {
    Tooltip,
    Card,
    CardContent,
    CardHeader,
    Grid
} from "@material-ui/core";


export default function OrderTableRow({ index, data, classes, openDetail, closeDetail, addTracking, confirmOrder, cancelOrder }) {
    return (
        <>
            <TableRow key={data._id}>
                <TableCell component="td" scope="row">
                    {data._id}
                </TableCell>
                <TableCell component="td" scope="row">
                    {data.created_at && DateTime.fromISO(data.created_at).setLocale("pt-BR").toFormat('dd/LL/yyyy HH:mm:ss')}
                </TableCell>
                <TableCell component="td" scope="row">
                    {data.partnerOrderID}
                </TableCell>
                <TableCell
                    component="td"
                    scope="row"
                    className={classes.cellRight}
                >
                    <NumberFormat
                        value={data.orderValue}
                        displayType={"text"}
                        thousandSeparator="."
                        prefix={""}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell
                    component="td"
                    scope="row"
                    className={classes.cellRight}
                >
                    <NumberFormat
                        value={data.productsValue}
                        displayType={"text"}
                        thousandSeparator="."
                        prefix={""}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell
                    component="td"
                    scope="row"
                    className={classes.cellRight}
                >
                    <NumberFormat
                        value={data.freight}
                        displayType={"text"}
                        thousandSeparator="."
                        prefix={""}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell component="td" scope="row">
                    {data.status.name}
                </TableCell>
                <TableCell component="td" scope="row">
                    {data.tracking[data.tracking.length - 1].tracking && data.tracking[data.tracking.length - 1].tracking.name}
                </TableCell>
                <TableCell component="td" scope="row">
                    {!data.distributionCenter ? "Nenhum" : data.distributionCenter.name}
                </TableCell>
                <TableCell component="td" scope="row">
                    {!data.visible && (
                        <Tooltip title="Detalhes do Pedido">
                            <IconButton
                                className={classes.button}
                                aria-label="Detalhes"
                                onClick={() => openDetail(index)}
                            >
                                <DetailsIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {data.visible && (
                        <Tooltip title="Detalhes do Pedido">
                            <IconButton
                                className={classes.button}
                                aria-label="Detalhes"
                                onClick={() => closeDetail(index)}
                            >
                                <ChangeHistoryIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Adicionar Tracking">
                        <IconButton
                            aria-label="adicionar"
                            onClick={() => addTracking(data._id)}
                        >
                            <PlaylistAddIcon />
                        </IconButton>
                    </Tooltip>
                    {data.status.identifier === 1 && (
                        <Tooltip title="Confirmar Pedido">
                            <IconButton
                                aria-label="confirmar"
                                onClick={() => confirmOrder(data._id)}
                            >
                                <AssignmentTurnedInIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {data.status.identifier === 1 && (
                        <Tooltip title="Cancelar Pedido">
                            <IconButton
                                aria-label="cancelar"
                                onClick={() => cancelOrder(data._id)}
                            >
                                <BlockIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>

            {
                data.visible && (
                    <TableRow>
                        <TableCell colSpan={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Detalhes da Entrega" />
                                        <CardContent>
                                            <p>
                                                <strong>Nome:</strong>
                                                {data.deliveryDetail.name}
                                            </p>
                                            <p>
                                                <strong>CPF:</strong>
                                                {data.deliveryDetail.federalDocument}
                                            </p>
                                            <p>
                                                <strong>Endereço:</strong>
                                                {data.deliveryDetail.address},{" "}
                                                {data.deliveryDetail.number}
                                            </p>
                                            <p>
                                                <strong>Bairro:</strong>
                                                {data.deliveryDetail.block}
                                            </p>
                                            <p>
                                                <strong>CEP:</strong>
                                                {data.deliveryDetail.zipcode}
                                            </p>
                                            <p>
                                                <strong>Cidade / Estado:</strong>
                                                {data.deliveryDetail.city} /{" "}
                                                {data.deliveryDetail.state}
                                            </p>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Produtos" />
                                        <CardContent>
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Quantidade</TableCell>
                                                        <TableCell>SKU</TableCell>
                                                        <TableCell>Descrição</TableCell>
                                                        <TableCell>Valor de Venda</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.details.map((od, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell component="td" scope="row">
                                                                {od.quantity}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                {od.product.sku}
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                {od.product.shortDescription}
                                                            </TableCell>
                                                            <TableCell
                                                                component="td"
                                                                scope="row"
                                                                className={classes.cellRight}
                                                            >
                                                                <NumberFormat
                                                                    value={od.product.salePrice}
                                                                    displayType={"text"}
                                                                    thousandSeparator="."
                                                                    prefix={""}
                                                                    decimalSeparator=","
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Tracking" />
                                        <CardContent>
                                            {data.tracking.length === 0 && (
                                                <Card>Sem informações de tracking!</Card>
                                            )}
                                            {data.tracking.length > 0 && (
                                                <OrderTrackingList trackings={data.tracking} />
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                )
            }
        </>
    )
}