import React from "react";
import Internal from "../layouts/Internal";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Link, Typography } from "@material-ui/core";
import config from "../config";

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column"
  }
}));

export default function Home(props) {
  const classes = useStyles();
  const [store, setStore] = React.useState(null);

  React.useEffect(() => {
    setStore(localStorage.getItem(config.STORAGE_NAME + "_store"));
  }, []);

  return (
    <Internal {...props} permission={["ADMIN", "STOREADMIN", "LOGISTIC"]}>
      <div className={classes.content}>
        <h2>Gestão da Loja do Cliente</h2>
        {!store && (
          <Card>
            <CardContent>
              <b>
                <Typography component="h4" variant="h5">
                  Clique <Link href="/store">aqui</Link>
                  {" para ativar uma loja! "}
                </Typography>
              </b>
            </CardContent>
          </Card>
        )}
        {/* <Card>
          <CardContent> */}
        <br />
        <Typography component="p">
          Gerencie seus produtos, pedidos e características de sua loja virtual.
        </Typography>
      </div>
    </Internal>
  );
}
