import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Toastr from "../../components/Toastr";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";

import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";

import GeneralException from "../../exception/GeneralException";
import OauthService from "../../services/oauthservice";
import StoreService from "../../services/storeservice";
import CdService from "../../services/cdservice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  formboxTitle: {
    textAlign: "center",
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30,
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    marginTop: 15,
  },
  imageBox: {
    width: "250px",
    height: "250px",
    border: "1px dashed rgb(195, 195, 195)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function CreateOrUpdateUser(props) {
  const classes = useStyles();
  const [message, setMessage] = React.useState(null);
  const [user, setUser] = React.useState({});
  const [stores, setStores] = React.useState([]);
  const [storesIds, setStoreIds] = React.useState([]);
  const [distributionCenters, setDistributionCenters] = React.useState([]);
  const [distributionCentersIds, setDistributionCentersIds] = React.useState(
    []
  );

  React.useEffect(() => {
    setUser(props.user);
    let storeIds = [];
    let distributionCentersIds = [];
    if (props.user.stores) {
      props.user.stores.map((s) => {
        storeIds.push(s._id);
      });
    }
    setStoreIds(storeIds);

    if (props.user.distributionCenters) {
      props.user.distributionCenters.map((cd) => {
        distributionCentersIds.push(cd._id);
      });
    }
    setDistributionCentersIds(distributionCentersIds);

    loadStores();
    loadDistributionCenters();
  }, [props.user]);

  React.useEffect(() => {
    loadDistributionCenters();
  }, [storesIds]);

  const loadStores = async () => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new StoreService(token.data.access_token).listAll();
      setStores(result.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const loadDistributionCenters = async () => {
    try {
      if (storesIds.length > 0) {
        let token = await new OauthService().gettoken();
        let result = await new CdService(token.data.access_token).listAll();
        let distributions = [];
        for (let index = 0; index < storesIds.length; index++) {
          const element = storesIds[index];
          const filter = result.data.value.filter(
            (d) => d.store._id === element
          );
          filter.map((f) => {
            distributions.push(f);
          });
        }
        setDistributionCenters(distributions);
      } else {
        setDistributionCenters([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    setStoreIds(event.target.value);
  };

  const handleChangeDistributionCenter = (event) => {
    setDistributionCentersIds(event.target.value);
  };

  const onCloseToastr = () => {
    setMessage(null);
  };

  const cancel = () => {
    props.onCancel();
  };

  const save = () => {
    let array_stores = [];
    let array_distributionCenters = [];
    for (let index = 0; index < storesIds.length; index++) {
      const element = storesIds[index];
      const store = stores.filter((s) => {
        return s._id === element;
      });
      if (store.length === 1) {
        array_stores.push(store[0]);
      }
    }
    for (let index = 0; index < distributionCentersIds.length; index++) {
      const element = distributionCentersIds[index];
      const distributionCenter = distributionCenters.filter((cd) => {
        return cd._id === element;
      });
      if (distributionCenter.length === 1) {
        array_distributionCenters.push(distributionCenter[0]);
      }
    }
    props.onSave({
      ...user,
      stores: array_stores,
      distributionCenters: array_distributionCenters,
    });
  };

  return (
    <div className={classes.formbox}>
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <Typography variant="h5" className={classes.formboxTitle}>
        Cadastro de Usuário
      </Typography>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>E-mail</FormLabel>
        <TextField
          type="email"
          id="email"
          name="email"
          className={classes.textField}
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Nome</FormLabel>
        <TextField
          type="text"
          id="name"
          name="name"
          className={classes.textField}
          value={user.name}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Senha</FormLabel>
        <TextField
          type="password"
          id="password"
          name="password"
          className={classes.textField}
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Confirme a Senha</FormLabel>
        <TextField
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          className={classes.textField}
          value={user.confirmPassword}
          onChange={(e) =>
            setUser({ ...user, confirmPassword: e.target.value })
          }
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Tipo de Acesso</FormLabel>
        <Select
          value={user.usertype ? user.usertype.identifier : ""}
          onChange={(e) =>
            setUser({
              ...user,
              usertype: { ...user.usertype, identifier: e.target.value },
            })
          }
        >
          <MenuItem value="ADMIN">Administrador</MenuItem>
          <MenuItem value="STOREADMIN">Administrador de Loja</MenuItem>
          <MenuItem value="LOGISTIC">Operador Logístico</MenuItem>
        </Select>
      </FormGroup>
      {user.usertype && user.usertype.identifier !== "ADMIN" && (
        <FormGroup>
          <FormLabel className={classes.formboxLabel}>Lojas</FormLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={user && storesIds}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => {
                  let store_name = "";
                  let store_id = "0";
                  const store = stores.filter((a) => {
                    return a._id === value;
                  });
                  if (store[0] !== undefined) {
                    store_name = store[0].name;
                    store_id = store[0]._id;
                  }
                  if (store_name !== "") {
                    return (
                      <Chip
                        key={store_id}
                        label={store_name}
                        className={classes.chip}
                      />
                    );
                  }
                })}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {stores.map((store) => (
              <MenuItem key={store._id} value={store._id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
      )}
      {user.usertype && user.usertype.identifier === "LOGISTIC" && (
        <FormGroup>
          <FormLabel className={classes.formboxLabel}>
            Centros de Distribuição
          </FormLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={user && distributionCentersIds}
            onChange={handleChangeDistributionCenter}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => {
                  let name = "";
                  let id = "0";
                  const distributionCenter = distributionCenters.filter(
                    (cd) => {
                      return cd._id === value;
                    }
                  );
                  if (distributionCenter[0] !== undefined) {
                    name = distributionCenter[0].name;
                    id = distributionCenter[0]._id;
                  }
                  if (name !== "") {
                    return (
                      <Chip key={id} label={name} className={classes.chip} />
                    );
                  }
                })}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {distributionCenters.map((cd) => (
              <MenuItem key={cd._id} value={cd._id}>
                {cd.store.name} - {cd.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
      )}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="add"
        className={classes.margin}
        onClick={() => cancel()}
      >
        Cancelar
      </Fab>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.margin}
        onClick={() => save()}
      >
        Gravar
      </Fab>
    </div>
  );
}
