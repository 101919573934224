import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    cellRight: {
        textAlign: "right"
    }
}));

export default function OrderTrackingList(props) {
    const classes = useStyles();
    const [trackings, setTrackings] = React.useState([]);

    React.useEffect(() => {
        console.log(props.trackings);
        setTrackings(props.trackings);
    }, [props.trackings]);

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "15%" }}>Identificador</TableCell>
                        <TableCell style={{ width: "55%" }}>Descrição</TableCell>
                        <TableCell style={{ width: "30%" }}>Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {trackings.map(t => (
                        <TableRow key={t._id}>
                            <TableCell component="td" scope="row">
                                {t.tracking && t.tracking.identifier}
                            </TableCell>
                            <TableCell component="td" scope="row">
                                {t.tracking && t.tracking.name}
                            </TableCell>
                            <TableCell component="td" scope="row">
                                {t.tracking && t.insertDate}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}
