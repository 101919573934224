import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import OrderTableRow from "../../components/Order/OrderTableRow";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  cellRight: {
    textAlign: "right"
  },
  cellCenter: {
    textAlign: "center"
  }
}));

export default function OrderList(props) {
  const classes = useStyles();
  const [orders, setOrders] = React.useState([]);

  React.useEffect(() => {
    setOrders(props.orders);
  }, [props.orders]);

  const openDetail = index => {
    const newOrders = [];
    orders.map((o, i) => {
      if (i === index) {
        o.visible = true;
      }
      newOrders.push(o);
    });
    setOrders(newOrders);
  };

  const closeDetail = index => {
    const newOrders = [];
    orders.map((o, i) => {
      if (i === index) {
        o.visible = false;
      }
      newOrders.push(o);
    });
    setOrders(newOrders);
  };

  const addTracking = _id => {
    props.toggleFormTracking(_id);
  };

  const confirmOrder = _id => {
    props.onConfirm(_id);
  };

  const cancelOrder = _id => {
    props.onCancel(_id);
  };

  const addTrackingCode = order => {  
    props.toggleFormTrackingCode(order);
  }

  return (
    <>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellCenter}>ID Pedido</TableCell>
              <TableCell className={classes.cellCenter}>Data do Pedido</TableCell>
              <TableCell className={classes.cellCenter}>Código de Rastreio</TableCell>
              <TableCell className={classes.cellCenter}>
                Pedido Parceiro
              </TableCell>
              <TableCell className={classes.cellCenter}>
                Valor do Pedido
              </TableCell>
              <TableCell className={classes.cellCenter}>
                Valor de Produtos
              </TableCell>
              <TableCell className={classes.cellCenter}>Frete</TableCell>
              <TableCell className={classes.cellCenter}>
                Status do Pedido
              </TableCell>
              <TableCell className={classes.cellCenter}>
                Status de Entrega
              </TableCell>
              <TableCell className={classes.cellCenter}>
                Centro de Distribuição
              </TableCell>
              <TableCell width="20%" className={classes.cellCenter}>
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((o, index) => (
              <OrderTableRow
                key={o._id}
                index={index}
                data={o}
                classes={classes}
                openDetail={openDetail}
                closeDetail={closeDetail}
                addTracking={addTracking}
                confirmOrder={confirmOrder}
                cancelOrder={cancelOrder}
                addTrackingCode = {addTrackingCode}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
