import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import AccountCircle from "@material-ui/icons/AccountCircle";
import StoreIcon from "@material-ui/icons/Store";
import OrderIcon from "@material-ui/icons/FormatListBulleted";
import ProductsIcon from "@material-ui/icons/ListAlt";
import BlockIcon from "@material-ui/icons/Block";
import PeopleAltIcon from "@material-ui/icons/People";

import LinearProgress from "@material-ui/core/LinearProgress";

import config from "../config";
import StoreEnabled from "../components/StoreEnabled";
import { Link } from "@material-ui/core";
import { BusinessOutlined } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  progressBar: {
    width: "100%",
    position: "fixed",
    zIndex: 5000
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  linkColor: {
    color: "white"
  }
}));

export default function Internal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [userType, setUserType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElStores, setAnchorElStores] = React.useState(null);
  const [stores, setStores] = React.useState([]);
  const menuopen = Boolean(anchorEl);
  const menuopenstores = Boolean(anchorElStores);

  React.useEffect(() => {
    setProgress(props.progress);
  }, [props.progress]);

  React.useEffect(() => {
    const info = localStorage.getItem(config.STORAGE_NAME + "_info");

    if (!info) {
      props.history.push("/login");
    } else {
      const infoObj = JSON.parse(
        atob(localStorage.getItem(config.STORAGE_NAME + "_info"))
      );
      setStores(infoObj.stores);
      setUserType(infoObj.usertype.identifier);
    }
  }, []);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuStore(event) {
    setAnchorElStores(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleCloseStores() {
    setAnchorElStores(null);
  }

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const goProducts = () => {
    props.history.push("/products");
  };

  const goStore = () => {
    props.history.push("/store");
  };

  const go = url => {
    props.history.push(url);
  };

  const changePassword = () => {
    props.history.push("/changepassword");
  };

  const logout = () => {
    localStorage.clear();
    props.history.push("/login");
  };

  const chooseStore = store_id => {
    setAnchorElStores(null);
    localStorage.setItem(config.STORAGE_NAME + "_store", store_id);
    props.history.push("/");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            <Link className={classes.linkColor} href="/">
              iPremi Store
            </Link>
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenu}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={menuopen}
              onClose={handleClose}
            >
              <MenuItem onClick={() => changePassword()}>
                Alterar Senha
              </MenuItem>
              {/* <MenuItem>
                <span onClick={handleMenuStore}>Lojas</span>
                <Menu
                  id="menu-stores"
                  anchorEl={anchorElStores}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={menuopenstores}
                  onClose={handleCloseStores}
                >
                  {stores.map(s => (
                    <MenuItem key={s._id} onClick={() => chooseStore(s._id)}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Menu>
              </MenuItem> */}
              <MenuItem onClick={() => logout()}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {progress && (
        <LinearProgress color="secondary" className={classes.progressBar} />
      )}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {userType === "ADMIN" && (
            <ListItem button key={"users"} onClick={() => go("/users")}>
              <Tooltip title="Usuários">
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Usuários"} />
            </ListItem>
          )}
          {(userType === "ADMIN" || userType === "STOREADMIN") && (
            <ListItem button key={"Loja"} onClick={() => go("/store")}>
              <Tooltip title="Loja">
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Loja"} />
            </ListItem>
          )}
          {(userType === "ADMIN" || userType === "STOREADMIN") && (
            <ListItem button key={"Produtos"} onClick={() => go("/products")}>
              <Tooltip title="Produtos">
                <ListItemIcon>
                  <ProductsIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Produtos"} />
            </ListItem>
          )}
          {(userType === "ADMIN" || userType === "STOREADMIN") && (
            <ListItem button key={"Centro de Distribuição"} onClick={() => go("/distributionCenters")}>
              <Tooltip title="Centro de Distribuição">
                <ListItemIcon>
                  <BusinessOutlined />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Centro de Distribuição"} />
            </ListItem>
          )}
          <ListItem button key={"Pedidos"} onClick={() => go("/orders")}>
            <Tooltip title="Pedidos">
              <ListItemIcon>
                <OrderIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={"Pedidos"} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.permission.filter(t => t === userType).length > 0 && (
          <>
            <Typography variant="h4" component="h4">
              {props.title}
            </Typography>
            {props.children}
            {/* <StoreEnabled {...props} /> */}
          </>
        )}
        {props.permission.filter(t => t === userType).length === 0 && (
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4" component="h4">
              ACESSO NEGADO!
            </Typography>
          </div>
        )}
      </main>
    </div>
  );
}
