import React from "react";
import Internal from "../../layouts/Internal";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import OauthService from "../../services/oauthservice";
import UserService from "../../services/userservice";
import config from "../../config";
import GeneralException from "../../exception/GeneralException";
import Toastr from "../../components/Toastr";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function ChangePassword(props) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const change = async () => {
    try {
      setProgress(true);
      let token = await new OauthService().gettoken();
      let result = await new UserService(token.data.access_token)
        .changePassword({
          _id: JSON.parse(
            atob(localStorage.getItem(config.STORAGE_NAME + "_info"))
          )._id,
          oldpassword: oldPassword,
          newpassword: newPassword,
          confirmpassword: confirmPassword
        })
        .catch(e => {
          throw new GeneralException(e);
        });
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setMessage("Senha alterada com sucesso!");
      setProgress(false);
    } catch (e) {
      console.log(e);
      setProgress(false);
      if (e.exception) {
        setMessage(e.exception.response.data.message);
      }
    }
  };

  const onCloseToastr = () => {
    setMessage(null);
  };

  return (
    <Internal
      {...props}
      title="Alterar Senha"
      progress={false}
      permission={["ADMIN", "STOREADMIN", "LOGISTIC"]}
    >
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <Container component="main" maxWidth="xs">
        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="old_password"
            label="Senha Anterior"
            type="password"
            id="old_password"
            value={oldPassword}
            autoComplete="current-password"
            onChange={e => setOldPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="new_password"
            label="Nova Senha"
            type="password"
            id="new_password"
            value={newPassword}
            autoComplete="current-password"
            onChange={e => setNewPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm_password"
            label="Confirme a Senha"
            type="password"
            id="confirm_password"
            value={confirmPassword}
            autoComplete="current-password"
            onChange={e => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => change()}
          >
            Alterar
          </Button>
        </div>
      </Container>
    </Internal>
  );
}
