import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import config from "../../config";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  cell1: {
    width: "80%"
  },
  cell2: {
    width: "20%",
    textAlign: "right"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  button: {
    margin: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  logoStore: {
    marginRight: 15,
    width: 60
  },
  divLogoName: {
    display: "flex",
    alignItems: "center"
  }
}));

export default function ListStore(props) {
  const classes = useStyles();
  const [store_id, setStoreId] = React.useState(null);
  const [stores, setStores] = React.useState([]);

  React.useEffect(() => {
    setStores(props.stores);
    setStoreId(localStorage.getItem(config.STORAGE_NAME + "_store"));
  }, [props.stores]);

  React.useEffect(() => {
    console.log("OK");
  }, [store_id]);

  const activate = id => {
    setStoreId(id);
    localStorage.setItem(config.STORAGE_NAME + "_store", id);
  };

  const edit = id => {
    props.onEdit(id);
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell1} component="th">
              Nome da Loja
            </TableCell>
            <TableCell className={classes.cell2} component="th">
              {}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stores.map(s => (
            <TableRow key={s._id}>
              <TableCell scope="row">
                <div className={classes.divLogoName}>
                  <img src={s.logo} alt="" className={classes.logoStore} />
                  {s.name}
                </div>
              </TableCell>
              <TableCell className={classes.cell2}>
                <IconButton
                  onClick={() => edit(s._id)}
                  className={classes.button}
                  aria-label="editar"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => activate(s._id)}
                  className={classes.button}
                  aria-label="ativar"
                >
                  {store_id === s._id ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
