import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { capitalize } from "@material-ui/core/utils";
import Internal from "../../layouts/Internal";
import UserService from "../../services/userservice";
import OauthService from "../../services/oauthservice";
import ListUsers from "./list";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import AddIcon from "@material-ui/icons/Add";
import CreateOrUpdateUser from "./createOrUpdate";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  speedDial: {
    position: "fixed",
    "&$directionUp, &$directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(3)
    },
    "&$directionDown, &$directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(3)
    }
  },
  directionUp: {},
  directionRight: {},
  directionDown: {},
  directionLeft: {},
  formswipeable: {
    width: "85%"
  }
}));

const actions = [{ icon: <AddIcon />, name: "Usuário", action: "one" }];

export default function Users(props) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);
  const [userInsert, setUserInsert] = React.useState(false);

  React.useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new UserService(token.data.access_token).list();
      //console.log(result);
      setUsers(result.data.value);
    } catch (e) {
      setUsers(null);
    }
  };

  const getUser = async id => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new UserService(token.data.access_token).get(id);
      const user_temp = result.data.value;
      setUser({ ...user_temp, confirmPassword: user_temp.password });
      setUserInsert(true);
    } catch (e) {}
  };

  const createUpdate = async user => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new UserService(
        token.data.access_token
      ).createOrUpdate(user);
      await getUsers();
    } catch (e) {}
  };

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setUserInsert(open);
  };

  const speedDialClassName = clsx(
    classes.speedDial,
    classes[`direction${capitalize(direction)}`]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = action => {
    switch (action.action) {
      case "one":
        setUser({
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          usertype: { identifier: "" }
        });
        setUserInsert(true);
        break;
      default:
        break;
    }

    setOpen(prevOpen => !prevOpen);
  };

  const cancelAddUpdate = () => {
    setUserInsert(false);
  };

  const addUpdate = async user => {
    await createUpdate(user);
    setUserInsert(false);
  };

  const editUser = async id => {
    await getUser(id);
  };

  const removeUser = async id => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new UserService(token.data.access_token).remove(id);
      console.log(result);
      await getUsers();
    } catch (e) {}
  };

  return (
    <Internal
      {...props}
      progress={progress}
      permission={["ADMIN"]}
      title="Gestão de Usuários"
    >
      <ListUsers
        {...props}
        users={users}
        onEdit={id => editUser(id)}
        onRemove={id => removeUser(id)}
      />

      <SpeedDial
        ariaLabel="SpeedDial"
        className={speedDialClassName}
        hidden={false}
        icon={<SpeedDialIcon />}
        onBlur={handleClose}
        onClick={handleClick}
        onClose={handleClose}
        onFocus={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        open={open}
        direction={direction}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action)}
          />
        ))}
      </SpeedDial>

      <SwipeableDrawer
        anchor="right"
        open={userInsert}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{
          paper: classes.formswipeable
        }}
      >
        <CreateOrUpdateUser
          user={user}
          onCancel={() => cancelAddUpdate()}
          onSave={user => addUpdate(user)}
        />
      </SwipeableDrawer>
    </Internal>
  );
}
