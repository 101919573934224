import React from "react";
import NumberFormat from "react-number-format";

/* Colocar esta propriedade onde utilizar o controle */
/*
NumberField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
*/

export default function NumberField(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator={"."}
      prefix={""}
      decimalSeparator={","}
      decimalScale={2}
      fixedDecimalScale={false}
    />
  );
}
