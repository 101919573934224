import axios from "axios";
import config from "../config";

export default class OrderService {
  constructor(token) {
    this.token = token;
  }

  base() {
    if (this.token !== "") {
      return axios.create({
        baseURL: config.API_LOCATION,
        headers: {
          "Content-Type": "application/json",
          "Content-Language": "pt_BR",
          Accept: "application/json",
          Authorization: "Bearer " + this.token
        }
      });
    } else {
      return axios.create({
        baseURL: config.API_LOCATION,
        headers: {
          "Content-Type": "application/json",
          "Content-Language": "pt_BR",
          Accept: "application/json"
        }
      });
    }
  }

  listOrdersByStore(body) {
    const store = localStorage.getItem(config.STORAGE_NAME + "_store");
    const infoObj = JSON.parse(atob(localStorage.getItem(config.STORAGE_NAME + "_info")));
    let url = `order/ordersByStore/${store}`;

    if (infoObj.distributionCenter){
      url = url + `/${infoObj.distributionCenter._id}`;
    }
    
    if (body) {
      return this.base().post(url, body);
    } else {
      return this.base().post(url);
    }
  }

  exportByStore() {
    const store = localStorage.getItem(config.STORAGE_NAME + "_store");
    const infoObj = JSON.parse(atob(localStorage.getItem(config.STORAGE_NAME + "_info")));
    let url = `order/exportByStore/${store}`;

    if (infoObj.distributionCenter){
      url = url + `/${infoObj.distributionCenter._id}`;
    }

    return this.base().get(url);
  }

  addTracking(orderId, trackingId) {
    const body = {
      orderId,
      trackingId
    };
    return this.base().patch("order/addTracking", body);
  }

  confirmOrder(orderId) {
    const body = {
      _id: orderId
    };
    return this.base().post(`order/confirmOrder`, body);
  }

  cancelOrder(orderId) {
    return this.base().delete(`order/cancelOrder/${orderId}`);
  }
}
