import axios from "axios";
import config from "../config";

export default class CorreiosPostingTypeService {
  constructor(token) {
    this.token = token;
  }

  base() {
    if (this.token !== "") {
      return axios.create({
        baseURL: config.API_LOCATION,
        headers: {
          "Content-Type": "application/json",
          "Content-Language": "pt_BR",
          Accept: "application/json",
          Authorization: "Bearer " + this.token
        }
      });
    } else {
      return axios.create({
        baseURL: config.API_LOCATION,
        headers: {
          "Content-Type": "application/json",
          "Content-Language": "pt_BR",
          Accept: "application/json"
        }
      });
    }
  }

  list() {
    return this.base().get(`correiospostingtypes`);
  }
}
