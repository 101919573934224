import React from "react";
import { Typography, Card, CardContent, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NumberFormat from "react-number-format";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  button: {
    margin: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

export default function ListProduct(props) {
  const classes = useStyles();

  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    setProducts(props.products);
  }, [props.products]);

  const editProduct = _id => {
    props.onEdit(_id);
  };

  const imagesProduct = _id => {
    props.onImages(_id);
  };

  const handleChange = index => event => {
    props.onUpdate(index);
  };

  return (
    <>
      {products.length === 0 && (
        <Card>
          <CardContent>
            Não há produtos cadastrados ou <br />
            <br />
            <b>
              Não existe uma loja ativa, clique <Link href="/store">aqui</Link>
              {" para ativar uma loja "}
            </b>
            !
          </CardContent>
        </Card>
      )}
      {products.length > 0 && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>EAN</TableCell>
                <TableCell>Curta Descrição</TableCell>
                <TableCell>Preço De (R$)</TableCell>
                <TableCell>Preço Por (R$)</TableCell>
                <TableCell>Frete Produto (R$)</TableCell>
                <TableCell>Disponível em Estoque (un.)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>{}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, index) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.sku}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.ean}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.shortDescription}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <NumberFormat
                      value={row.salePrice}
                      displayType={"text"}
                      thousandSeparator="."
                      prefix={""}
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <NumberFormat
                      value={row.listPrice}
                      displayType={"text"}
                      thousandSeparator="."
                      prefix={""}
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <NumberFormat
                      value={row.fixedfreight}
                      displayType={"text"}
                      thousandSeparator="."
                      prefix={""}
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <NumberFormat
                      value={row.stock.available}
                      displayType={"text"}
                      thousandSeparator="."
                      prefix={""}
                      decimalSeparator=","
                      decimalScale={0}
                      fixedDecimalScale={true}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Switch
                      checked={row.active}
                      onChange={handleChange(index)}
                      value="active"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => editProduct(row._id)}
                      className={classes.button}
                      aria-label="editar"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => imagesProduct(row._id)}
                      className={classes.button}
                      aria-label="Imagens"
                    >
                      <PhotoLibraryIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </>
  );
}
