import React from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import OauthService from "../../services/oauthservice";
import ProductService from "../../services/productservice";
import GeneralException from "../../exception/GeneralException";
import Toastr from "../../components/Toastr";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  newImage: {
    width: "292px",
    height: "292px",
    border: "1px dashed #c3c3c3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px"
  },
  newImageIcon: {
    width: "3em",
    height: "3em",
    color: "#c3c3c3"
  },
  newImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  loadedImage: {
    width: "292px",
    height: "292px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    position: "relative"
  },
  actionButton: {
    position: "absolute",
    top: 0,
    right: 0
  },
  removeActionButton: {
    color: "#ff0000"
  }
}));

export default function ImagesProducts(props) {
  const classes = useStyles();

  const [product, setProduct] = React.useState({});
  const [images, setImages] = React.useState([]);
  const [message, setMessage] = React.useState(null);

  React.useEffect(() => {
    setProduct(props.product);
    setImages(props.product.images);
  }, [props.product]);

  const uploadImage = () => {
    document.getElementById("uploadImage").click();
  };

  const fileChangedHandler = async event => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    data.append("width", 292);
    data.append("height", 292);
    try {
      let token = await new OauthService().gettoken();
      let upload = await new ProductService(token.data.access_token)
        .uploadProductImage(data)
        .catch(e => {
          throw new GeneralException(e);
        });
      const imageurl = upload.data.value;
      images.push({ imageUrl: imageurl });
      setImages(images);
      props.onUpdateImageList(product._id, images);
    } catch (e) {
      console.log(e);
      if (e.exception) {
        setMessage(e.exception.response.data.message);
      }
    }
  };

  const removeImage = index => {
    images.splice(index, 1);
    props.onUpdateImageList(product._id, images);
  };

  const onCloseToastr = () => {
    setMessage(null);
  };

  return (
    <div className={classes.formbox}>
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <Typography variant="h5" className={classes.formboxTitle}>
        Imagens de Produtos
      </Typography>
      <Typography variant="h6" className={classes.formboxTitle}>
        {product.sku + " " + product.shortDescription}
      </Typography>
      <input
        type="file"
        id="uploadImage"
        style={{ display: "none" }}
        onChange={fileChangedHandler}
      />
      <Grid container>
        <Grid item xs={4} className={classes.newImage}>
          <div className={classes.newImageContainer}>
            <div>
              <IconButton onClick={() => uploadImage()}>
                <AddPhotoAlternateIcon className={classes.newImageIcon} />
              </IconButton>
            </div>
            <span>Carregar as imagens dos produtos com 292x292px</span>
          </div>
        </Grid>
        {images.map((i, index) => (
          <Grid item xs={4} className={classes.loadedImage} key={index}>
            <img src={i.imageUrl} alt="" width="292px" />
            <IconButton
              onClick={() => removeImage(index)}
              className={classes.actionButton}
            >
              <RemoveCircleIcon className={classes.removeActionButton} />
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
