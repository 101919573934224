import axios from "axios";
import config from "../config";

export default class StoreService {
  constructor(token) {
    this.token = token;
  }

  base() {
    if (this.token !== "") {
      return axios.create({
        baseURL: config.API_LOCATION,
        headers: {
          "Content-Type": "application/json",
          "Content-Language": "pt_BR",
          Accept: "application/json",
          Authorization: "Bearer " + this.token
        }
      });
    } else {
      return axios.create({
        baseURL: config.API_LOCATION,
        headers: {
          "Content-Type": "application/json",
          "Content-Language": "pt_BR",
          Accept: "application/json"
        }
      });
    }
  }

  get(store) {
    //const store = localStorage.getItem(config.STORAGE_NAME + "_store");
    return this.base().get(`store/${store}`);
  }

  uploadStoreLogo(body) {
    return this.base().post("store/uploadStoreLogo", body);
  }

  create(data) {
    return this.base().post("store", data);
  }

  listAll() {
    return this.base().get("stores");
  }
}
