import React from "react";
import config from "../config";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  cardNostore: {
    maxWidth: "100%",
    padding: 10,
    textAlign: "center",
    marginTop: 20
  }
}));

export default function StoreEnabled(props) {
  const classes = useStyles();
  const [hasStore, setHasStore] = React.useState(null);
  const [stores, setStores] = React.useState([]);

  React.useEffect(() => {
    const infoObj = JSON.parse(
      atob(localStorage.getItem(config.STORAGE_NAME + "_info"))
    );
    setStores(infoObj.stores);
    setHasStore(localStorage.getItem(config.STORAGE_NAME + "_store"));
  }, []);

  return (
    <div>
      {!hasStore && (
        <Card className={classes.cardNostore}>
          <Typography variant="h5" component="h2">
            Nenhuma loja foi selecionada!
          </Typography>
        </Card>
      )}
      {hasStore && props.children}
    </div>
  );
}
