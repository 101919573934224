import React from "react";
import MaskedInput from "react-text-mask";

/* Colocar esta propriedade onde utilizar o controle */
/*
MaskText.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
*/

export default function EanMaskText(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[1-9]/,
        " ",
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        " ",
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/
      ]}
      placeholderChar={"_"}
      showMask={true}
    />
  );
}
