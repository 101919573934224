import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { capitalize } from "@material-ui/core/utils";
import Internal from "../../layouts/Internal";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import GeneralException from "../../exception/GeneralException";
import OauthService from "../../services/oauthservice";
import StoreService from "../../services/storeservice";
import config from "../../config";
import Toastr from "../../components/Toastr";

import ListStore from "./liststore";
import CreateOrUpdate from "./createOrUpdate";

import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: "25px",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    marginBottom: "50px"
  },
  card: {
    maxWidth: 600,
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  media: {
    height: "auto"
  },
  labelTextField: {
    marginTop: 15
  },
  zipPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)"
  },
  cardNostore: {
    maxWidth: "100%",
    padding: 10,
    textAlign: "center",
    marginTop: 60
  },
  speedDial: {
    position: "fixed",
    "&$directionUp, &$directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(3)
    },
    "&$directionDown, &$directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(3)
    }
  },
  directionUp: {},
  directionRight: {},
  directionDown: {},
  directionLeft: {},
  formswipeable: {
    width: "85%"
  }
}));

const actions = [{ icon: <AddIcon />, name: "Loja", action: "one" }];

export default function Store(props) {
  const classes = useStyles();
  const [message, setMessage] = React.useState(null);
  const [progress, setProgress] = React.useState(false);
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);
  const [storeInsert, setStoreInsert] = React.useState(false);
  const [acl, setAcl] = React.useState(null);

  const [store, setStore] = React.useState(null);

  const [stores, setStores] = React.useState([]);

  React.useEffect(() => {
    setProgress(true);
    loadStores();
    //loadFreight();
    //loadStore();
    setProgress(false);
  }, []);

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStoreInsert(open);
  };

  const loadStores = async () => {
    try {
      const infoObj = JSON.parse(
        atob(localStorage.getItem(config.STORAGE_NAME + "_info"))
      );

      if (infoObj.usertype.identifier === "ADMIN") {
        let token = await new OauthService().gettoken();
        let result = await new StoreService(token.data.access_token).listAll();
        setStores(result.data.value);
        setAcl(infoObj.usertype.identifier);
      } else {
        setStores(infoObj.stores);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadStore = async id => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new StoreService(token.data.access_token).get(id);
      setStore(result.data.value);
      setStoreInsert(true);
    } catch (e) {
      setStore(null);
      console.log("Nenhuma loja selecionada!");
    }
  };

  const onCloseToastr = () => {
    setMessage(null);
  };

  const speedDialClassName = clsx(
    classes.speedDial,
    classes[`direction${capitalize(direction)}`]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = action => {
    switch (action.action) {
      case "one":
        setStore({
          documents: {
            federalDocument: "",
            stateDocument: "",
            municipalDocument: ""
          },
          address: {
            address: "",
            number: "",
            block: "",
            city: "",
            state: "",
            zipCode: ""
          },
          freightType: {
            freight: "",
            value: ""
          }
        });
        setStoreInsert(true);
        break;
      default:
        break;
    }

    setOpen(prevOpen => !prevOpen);
  };

  const cancelAddUpdate = () => {
    setStoreInsert(false);
  };

  const addUpdate = async store => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new StoreService(token.data.access_token)
        .create(store)
        .catch(e => {
          throw new GeneralException(e);
        });
      await loadStores();
      setMessage("Dados atualizados com sucesso!");
      setStoreInsert(false);
    } catch (e) {
      if (e.exception) {
        setMessage(e.exception.response.data.value);
      }
    }
  };

  const edit = async id => {
    await loadStore(id);
  };

  return (
    <Internal
      {...props}
      progress={progress}
      permission={["ADMIN", "STOREADMIN"]}
      title="Gestão da Loja"
    >
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <ListStore stores={stores} onEdit={id => edit(id)} />

      {acl === "ADMIN" && (
        <SpeedDial
          ariaLabel="SpeedDial"
          className={speedDialClassName}
          hidden={false}
          icon={<SpeedDialIcon />}
          onBlur={handleClose}
          onClick={handleClick}
          onClose={handleClose}
          onFocus={handleOpen}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          open={open}
          direction={direction}
        >
          {actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handleClick(action)}
            />
          ))}
        </SpeedDial>
      )}

      <SwipeableDrawer
        anchor="right"
        open={storeInsert}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{
          paper: classes.formswipeable
        }}
      >
        <CreateOrUpdate
          store={store}
          onCancel={() => cancelAddUpdate()}
          onSave={store => addUpdate(store)}
        />
      </SwipeableDrawer>
    </Internal>
  );
}
