import axios from "axios";
import config from "../config";

export default class OauthService {
  base() {
    return axios.create({
      baseURL: config.API_LOCATION,
      headers: {
        "Content-Type": "application/json",
        "Content-Language": "pt_BR",
        Accept: "application/json"
      }
    });
  }

  gettoken() {
    const body = {
      client_name: config.CLIENT_NAME,
      password: config.CLIENT_PASSWORD,
      grant_type: "password",
      scope: config.CLIENT_SCOPE
    };
    return this.base().post(`oauth/authenticate`, body);
  }
}
