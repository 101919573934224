const dev = {
  APP_SERVER: "https://ipremistore-backend.ipremi.com.br/",
  API_LOCATION: "https://ipremistore-backend.ipremi.com.br/",
  API_SERVER: "https://ipremistore-backend.ipremi.com.br/",
  // APP_SERVER: "http://localhost:3005/",
  // API_LOCATION: "http://localhost:3005/",
  // API_SERVER: "http://localhost:3005/",
  CLIENT_NAME: "apiprod",
  CLIENT_PASSWORD: "QjxDcZoc1OTR",
  CLIENT_SCOPE: "*",
  STORAGE_NAME: "ipremistore",
  TINYMCE_KEY: "w7skps9rxy8jvedffptxiqfy9uhep6reue6afwszr3yxsh6e",
};

const hml = {
  API_LOCATION: "https://hml-api-ipremistore.ipremi.com.br/",
  API_SERVER: "https://hml-api-ipremistore.ipremi.com.br/",
  CLIENT_NAME: "apihml",
  CLIENT_PASSWORD: "e7XtM8JLGa7c",
  CLIENT_SCOPE: "*",
  STORAGE_NAME: "hmlstore",
  TINYMCE_KEY: "w7skps9rxy8jvedffptxiqfy9uhep6reue6afwszr3yxsh6e",
};

const prod = {
  APP_SERVER: "https://ipremistore-backend.ipremi.com.br/",
  API_LOCATION: "https://ipremistore-backend.ipremi.com.br/",
  API_SERVER: "https://ipremistore-backend.ipremi.com.br/",
  CLIENT_NAME: "apiprod",
  CLIENT_PASSWORD: "QjxDcZoc1OTR",
  CLIENT_SCOPE: "*",
  STORAGE_NAME: "ipremistore",
  TINYMCE_KEY: "w7skps9rxy8jvedffptxiqfy9uhep6reue6afwszr3yxsh6e",
};

let config = dev;

if (process.env.REACT_APP_STAGE === "production") {
  config = prod;
} else if (process.env.REACT_APP_STAGE === "hml") {
  config = hml;
} else {
  config = dev;
}

export default {
  // Add common config values here
  //MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
