import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Products from "./pages/Products";
import Store from "./pages/Store";
import Order from "./pages/Order";
import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";
import config from "./config";
import Users from "./pages/Users";
import DistributionCenters from "./pages/DistributionCenter";

const Rotas = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/products" component={Products} />
      <Route path="/distributionCenters" component={DistributionCenters} />
      <Route path="/store" component={Store} />
      <Route path="/orders" component={Order} />
      <Route path="/login" component={Login} />
      <Route path="/users" component={Users} />
      <Route path="/changepassword" component={ChangePassword} />
    </Switch>
  </BrowserRouter>
);

const App = () => <Rotas />;

export default App;
