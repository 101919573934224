import axios from "axios";
import config from "../config";

export default class ZipcodeService {
  base() {
    return axios.create({
      baseURL: "https://cep.ipremi.com.br/",
      headers: {
        "Content-Type": "application/json",
        "Content-Language": "pt_BR",
        Accept: "application/json"
      }
    });
  }

  get(zipcode) {
    return this.base().get(`ws/${zipcode.replace(/\-/g, "")}/json`);
  }
}
