import React from "react";
import config from "../../config";
import OauthService from "../../services/oauthservice";
import ProductService from "../../services/productservice";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

export default function BatchProduct() {
  const classes = useStyles();

  const [batcherrors, setBatcherrors] = React.useState([]);
  const [batchsuccess, setBatchsuccess] = React.useState("");

  const uploadBatch = () => {
    document.getElementById("uploadbatch").click();
  };

  const downloadTemplate = () => {
    window.open("/template/modelo-bathproduto.csv", "_blank");
  };

  const fileChangedHandler = async event => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    data.append("store", localStorage.getItem(config.STORAGE_NAME + "_store"));
    try {
      let token = await new OauthService().gettoken();
      let products = await new ProductService(
        token.data.access_token
      ).batchInsert(data);
      if (products.data.error) {
        setBatcherrors(products.data.value);
      } else {
        setBatchsuccess("ok");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.formbox}>
      <Typography variant="h5" className={classes.formboxTitle}>
        Cadastro de Produto em Lote
      </Typography>

      <Fab
        variant="extended"
        color="secondary"
        aria-label="add"
        className={classes.margin}
        onClick={() => downloadTemplate()}
      >
        <CloudDownloadIcon className={classes.extendedIcon} />
        Baixar modelo de arquivo
      </Fab>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.margin}
        onClick={() => uploadBatch()}
      >
        <CloudUploadIcon className={classes.extendedIcon} />
        Carregar Lote
      </Fab>
      <input
        type="file"
        id="uploadbatch"
        style={{ display: "none" }}
        onChange={fileChangedHandler}
      />
      {batchsuccess !== "" && (
        <Typography variant="h6" className={classes.formboxMessage}>
          Lote processado com sucesso!
        </Typography>
      )}
      {batcherrors.length > 0 && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Linha</TableCell>
                <TableCell>Erro</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batcherrors.map(row => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.line}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.message}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
}
