import React from "react";
import PropTypes from "prop-types";
import config from "../../config";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import { Editor } from "@tinymce/tinymce-react";

import OauthService from "../../services/oauthservice";
import CategoryService from "../../services/categoryservice";
import ProductService from "../../services/productservice";
import EanMaskText from "../../components/EanMaskText";
import NumberField from "../../components/NumberField";
import Toastr from "../../components/Toastr";

import NumberFormat from "react-number-format";

import GeneralException from "../../exception/GeneralException";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  imageBox: {
    width: "250px",
    height: "250px",
    border: "1px dashed rgb(195, 195, 195)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

EanMaskText.propTypes = {
  inputRef: PropTypes.func.isRequired
};

NumberField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function AddProduct(props) {
  const classes = useStyles();

  const [product, setProduct] = React.useState({});
  const [hasStock, setHasStock] = React.useState(false);
  const [minimum, setMinimum] = React.useState(0);
  const [available, setAvailable] = React.useState(0);
  const [parentCategories, setParentCategories] = React.useState([]);
  const [childrenCategories, setChildrenCategories] = React.useState([]);
  const [message, setMessage] = React.useState(null);

  const tinymce_config = {
    language_url: "/tinymce_languages/langs/pt_BR.js",
    language: "pt_BR",
    plugins: "link image code",
    menubar: false,
    toolbar:
      "formatselect | undo redo | bold italic | alignleft aligncenter alignright | code"
  };

  React.useEffect(() => {
    loadParentCategories();
    console.log(props.product);
    setProduct(props.product);
    if (props.product) {
      if (props.product.firstCategory !== "") {
        loadChildrenCategories(props.product.firstCategory);
      }
      setHasStock(props.product.stock.hasStock);
      setMinimum(props.product.stock.minimum);
      setAvailable(props.product.stock.available);
    }
  }, [props.product]);

  const loadParentCategories = async () => {
    try {
      let token = await new OauthService().gettoken();
      let parents = await new CategoryService(
        token.data.access_token
      ).parents();
      setParentCategories(parents.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const loadChildrenCategories = async _id => {
    try {
      let token = await new OauthService().gettoken();
      let childrens = await new CategoryService(
        token.data.access_token
      ).childrens(_id);
      setChildrenCategories(childrens.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const changeProduct = e => {
    const propname = e.target.name;
    const propvalue = e.target.value;
    setProduct({ ...product, [propname]: propvalue });
  };

  const changeProductName = (values, propname) => {
    setProduct({ ...product, [propname]: values.floatValue });
  };

  const changeProductContent = (e, propname) => {
    const propvalue = e.target.getContent();
    setProduct({ ...product, [propname]: propvalue });
  };

  const changeFirstCategory = e => {
    setProduct({ ...product, firstCategory: e.target.value });
    loadChildrenCategories(e.target.value);
  };

  const changeSecondCategory = e => {
    setProduct({ ...product, secondCategory: e.target.value });
  };

  const changeHasStock = e => {
    const stock = { ...product.stock, hasStock: e.target.value };
    setProduct({ ...product, stock: stock });
    setHasStock(e.target.value);
  };

  const changeMinimum = e => {
    const stock = { ...product.stock, minimum: e.target.value };
    setProduct({ ...product, stock: stock });
    setMinimum(e.target.value);
  };

  const changeAvailable = e => {
    const stock = { ...product.stock, available: e.target.value };
    setProduct({ ...product, stock: stock });
    setAvailable(e.target.value);
  };

  const save = () => {
    props.onSave(product);
  };

  const cancel = () => {
    props.onCancel();
  };

  const uploadShowCaseImage = () => {
    document.getElementById("uploadShowCaseImage").click();
  };

  const uploadDetailImage = () => {
    document.getElementById("uploadDetailImage").click();
  };

  const uploadLargeImage = () => {
    document.getElementById("uploadLargeImage").click();
  };

  const fileChangedHandler = async (event, type, w, h) => {
    console.log(type);
    const data = new FormData();
    data.append("file", event.target.files[0]);
    data.append("width", w);
    data.append("height", h);
    try {
      let token = await new OauthService().gettoken();
      let upload = await new ProductService(token.data.access_token)
        .uploadProductImage(data)
        .catch(e => {
          throw new GeneralException(e);
        });
      const imageurl = upload.data.value;
      console.log(imageurl);
      switch (type) {
        case "showcase":
          setProduct({ ...product, showCaseImage: imageurl });
          break;
        case "detail":
          setProduct({ ...product, detailImage: imageurl });
          break;
        case "large":
          setProduct({ ...product, largeImage: imageurl });
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e.exception.response);
      if (e.exception && e.exception.response && e.exception.response.data) {
        setMessage(e.exception.response.data.message);
      } else {
        setMessage('Erro ao carregar a imagem, verifique se não possui algum espaço em branco no nome do arquivo');
      }
    }
  };

  const onCloseToastr = () => {
    setMessage(null);
  };

  return (
    <div className={classes.formbox}>
      <input
        type="file"
        id="uploadShowCaseImage"
        style={{ display: "none" }}
        onChange={(e, type, w, h) =>
          fileChangedHandler(e, "showcase", 210, 210)
        }
      />
      <input
        type="file"
        id="uploadDetailImage"
        style={{ display: "none" }}
        onChange={(e, type) => fileChangedHandler(e, "detail", 410, 308)}
      />
      <input
        type="file"
        id="uploadLargeImage"
        style={{ display: "none" }}
        onChange={(e, type) => fileChangedHandler(e, "large", 1500, 1500)}
      />
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <Typography variant="h5" className={classes.formboxTitle}>
        Cadastro de Produto
      </Typography>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>SKU</FormLabel>
        <TextField
          id="sku"
          name="sku"
          className={classes.textField}
          value={product.sku}
          onChange={e => changeProduct(e)}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>EAN</FormLabel>
        <Input
          value={product.ean}
          onChange={e => changeProduct(e)}
          id="ean"
          name="ean"
          className={classes.textField}
          inputComponent={EanMaskText}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Descrição Breve</FormLabel>
        <TextField
          id="shortDescription"
          name="shortDescription"
          className={classes.textField}
          value={product.shortDescription}
          onChange={e => changeProduct(e)}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Descrição Longa</FormLabel>
        <Editor
          apiKey={config.TINYMCE_KEY}
          initialValue={product.longDescription}
          init={tinymce_config}
          onChange={e => changeProductContent(e, "longDescription")}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Especificação</FormLabel>
        <Editor
          apiKey={config.TINYMCE_KEY}
          initialValue={product.specification}
          init={tinymce_config}
          onChange={e => changeProductContent(e, "specification")}
        />
      </FormGroup>
      <FormGroup>
        <Grid container>
          <Grid item>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>Altura (m)</FormLabel>
              <NumberFormat
                id="height"
                name="height"
                className={classes.textField}
                customInput={TextField}
                value={product.height}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => changeProductName(values, "height")}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Largura (m)
              </FormLabel>
              <NumberFormat
                id="width"
                name="width"
                className={classes.textField}
                customInput={TextField}
                value={product.width}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => changeProductName(values, "width")}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Comprimento (m)
              </FormLabel>
              <NumberFormat
                id="length"
                name="length"
                className={classes.textField}
                customInput={TextField}
                value={product.length}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => changeProductName(values, "length")}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>Peso (kg)</FormLabel>
              <NumberFormat
                id="weight"
                name="weight"
                className={classes.textField}
                customInput={TextField}
                value={product.weight}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => changeProductName(values, "weight")}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Frete por Produto (R$)
              </FormLabel>
              <NumberFormat
                id="fixedfreight"
                name="fixedfreight"
                className={classes.textField}
                customInput={TextField}
                value={product.fixedfreight}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values =>
                  changeProductName(values, "fixedfreight")
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup>
        <Grid container>
          <Grid item>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Preço De (R$)
              </FormLabel>
              <NumberFormat
                id="salePrice"
                name="salePrice"
                className={classes.textField}
                customInput={TextField}
                value={product.salePrice}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => changeProductName(values, "salePrice")}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Preço Por (R$)
              </FormLabel>
              <NumberFormat
                className={classes.textField}
                customInput={TextField}
                value={product.listPrice}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                name="listPrice"
                onValueChange={values => changeProductName(values, "listPrice")}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Desconto (%)
              </FormLabel>
              <NumberFormat
                className={classes.textField}
                customInput={TextField}
                value={product.discount}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                name="discount"
                onValueChange={values => changeProductName(values, "discount")}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Marca</FormLabel>
        <TextField
          id="brand"
          className={classes.textField}
          value={product.brand}
          name="brand"
          onChange={e => changeProduct(e)}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>
          Categoria Principal
        </FormLabel>
        <Select
          value={product.firstCategory}
          onChange={e => changeFirstCategory(e)}
          inputProps={{
            name: "firstcategory",
            id: "firstcatetory"
          }}
        >
          {parentCategories.map(pc => (
            <MenuItem value={pc._id} key={pc._id}>
              {pc.name}
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>
          Categoria Secundária
        </FormLabel>
        {childrenCategories.length === 0 && (
          <span>Selecione a categoria principal</span>
        )}
        {childrenCategories.length > 0 && (
          <Select
            value={product.secondCategory}
            onChange={e => changeSecondCategory(e)}
            inputProps={{
              name: "secondcategory",
              id: "secondcategory"
            }}
          >
            {childrenCategories.map(pc => (
              <MenuItem value={pc._id} key={pc._id}>
                {pc.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormGroup>
      <FormGroup className={classes.formboxLabel}>
        <InputLabel htmlFor="hasStock-id">Controla Estoque?</InputLabel>
        <Select
          value={hasStock}
          onChange={e => changeHasStock(e)}
          inputProps={{
            name: "hasStock",
            id: "hasStock-id"
          }}
        >
          <MenuItem value={false}>Não</MenuItem>
          <MenuItem value={true}>Sim</MenuItem>
        </Select>
      </FormGroup>
      <FormGroup>
        <InputLabel htmlFor="minimum">Estoque Mínimo</InputLabel>
        <TextField
          id="minimum"
          className={classes.textField}
          value={minimum}
          name="minimum"
          onChange={e => changeMinimum(e)}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <InputLabel htmlFor="available">Estoque Disponível</InputLabel>
        <TextField
          id="available"
          name="available"
          className={classes.textField}
          value={available}
          onChange={e => changeAvailable(e)}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Imagem Vitrine
                <IconButton onClick={() => uploadShowCaseImage()}>
                  <AddPhotoAlternateIcon className={classes.newImageIcon} />
                </IconButton>
              </FormLabel>
              <div className={classes.imageBox}>
                <img src={product.showCaseImage} alt="" width="200" />
              </div>
              <span>(210x210px)</span>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Imagem Detalhe
                <IconButton onClick={() => uploadDetailImage()}>
                  <AddPhotoAlternateIcon className={classes.newImageIcon} />
                </IconButton>
              </FormLabel>
              <div className={classes.imageBox}>
                <img src={product.detailImage} alt="" width="200" />
              </div>
              (410x308px)
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormGroup>
              <FormLabel className={classes.formboxLabel}>
                Imagem Grande
                <IconButton onClick={() => uploadLargeImage()}>
                  <AddPhotoAlternateIcon className={classes.newImageIcon} />
                </IconButton>
              </FormLabel>
              <div className={classes.imageBox}>
                <img src={product.largeImage} alt="" width="200" />
              </div>
              (1500x1500px)
            </FormGroup>
          </Grid>
        </Grid>
      </FormGroup>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="add"
        className={classes.margin}
        onClick={() => cancel()}
      >
        Cancelar
      </Fab>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.margin}
        onClick={() => save()}
      >
        Gravar
      </Fab>
    </div>
  );
}
