import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toastr from "../../components/Toastr";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

import NumberFormat from "react-number-format";

import OauthService from "../../services/oauthservice";
import StoreService from "../../services/storeservice";
import ZipcodeService from "../../services/zipcodeservice";
import config from "../../config";
import FreightService from "../../services/freightservice";
import CorreiosPostingTypeService from "../../services/correiosPostingTypeservice";
import GeneralException from "../../exception/GeneralException";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  imageBox: {
    width: "250px",
    height: "250px",
    border: "1px dashed rgb(195, 195, 195)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  formImage: {
    display: "flex",
    flex: "1",
    alignItems: "center"
  },
  zipPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)"
  }
}));

export default function CreateOrUpdate(props) {
  const classes = useStyles();
  const [message, setMessage] = React.useState(null);
  const [progress, setProgress] = React.useState(false);
  const [store, setStore] = React.useState(null);

  const [freights, setFreights] = React.useState([]);
  const [freight, setFreight] = React.useState(null);
  const [correiosPostingTypes, setCorreiosPostingTypes] = React.useState([]);

  React.useEffect(() => {
    setStore(props.store);
    getFreight(props.store.freightType.freight._id);
  }, [props.store]);

  React.useEffect(() => {
    loadFreight();
    loadCorreiosPostingTypes();
  }, []);

  const onCloseToastr = () => {
    setMessage(null);
  };

  const loadFreight = async () => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new FreightService(token.data.access_token).list();
      setFreights(result.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const getFreight = async _id => {
    try {
      if (_id !== "") {
        let token = await new OauthService().gettoken();
        let result = await new FreightService(token.data.access_token).get(_id);
        setFreight(result.data.value);
      } else {
        setFreight(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadCorreiosPostingTypes = async () => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new CorreiosPostingTypeService(
        token.data.access_token
      ).list();
      setCorreiosPostingTypes(result.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const searchZipCode = async () => {
    try {
      setProgress(true);
      console.log(store.address.zipCode);
      let result = await new ZipcodeService().get(store.address.zipCode);
      let zipcode = result.data;
      console.log(zipcode);
      if (zipcode.erro) {
        setStore({
          ...store,
          address: {
            ...store.address,
            address: "",
            number: "",
            block: "",
            city: "",
            state: ""
          }
        });
      } else {
        setStore({
          ...store,
          address: {
            ...store.address,
            address: zipcode.logradouro,
            number: "",
            block: zipcode.bairro,
            city: zipcode.localidade,
            state: zipcode.uf
          }
        });
      }
      setProgress(false);
    } catch (e) {
      setProgress(false);
      console.log(e);
    }
  };

  const updateImage = () => {
    document.getElementById("uploadImage").click();
  };

  const fileChangedHandler = async event => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    data.append(
      "store_id",
      localStorage.getItem(config.STORAGE_NAME + "_store")
    );
    try {
      setProgress(true);
      let token = await new OauthService().gettoken();
      let upload = await new StoreService(token.data.access_token)
        .uploadStoreLogo(data)
        .catch(e => {
          throw new GeneralException(e);
        });
      const imageurl = upload.data.value;
      setStore({ ...store, logo: upload.data.value });
      setProgress(false);
    } catch (e) {
      setProgress(false);
      if (e.exception) {
        setMessage(e.exception.response.data.message);
      }
    }
  };

  const save = async () => {
    props.onSave(store);
  };

  const cancel = () => {
    props.onCancel();
  };

  return (
    <div className={classes.formbox}>
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <Typography variant="h5" className={classes.formboxTitle}>
        Cadastro de Loja
      </Typography>
      <FormGroup className={classes.formImage}>
        <img
          className={classes.imageBox}
          src={store && store.logo}
          alt=""
          title="Clique aqui para carregar ou substituir a imagem (400 x 400px)"
          onClick={() => updateImage()}
        />
        <input
          type="file"
          id="uploadImage"
          style={{ display: "none" }}
          onChange={fileChangedHandler}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Nome</FormLabel>
        <TextField
          type="text"
          id="name"
          name="name"
          className={classes.textField}
          value={store && store.name}
          onChange={e => setStore({ ...store, name: e.target.value })}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>CNPJ</FormLabel>
        <NumberFormat
          id="federalDocument"
          name="federalDocument"
          className={classes.textField}
          customInput={TextField}
          format={"##.###.###/####-##"}
          value={store && store.documents.federalDocument}
          onChange={e => {
            setStore({
              ...store,
              documents: { ...store.documents, federalDocument: e.target.value }
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>
          Inscrição Estadual
        </FormLabel>
        <TextField
          type="text"
          id="stateDocument"
          name="stateDocument"
          className={classes.textField}
          value={store && store.documents.stateDocument}
          onChange={e => {
            setStore({
              ...store,
              documents: { ...store.documents, stateDocument: e.target.value }
            });
          }}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>CCM</FormLabel>
        <NumberFormat
          id="municipalDocument"
          name="municipalDocument"
          className={classes.textField}
          customInput={TextField}
          value={store && store.documents.municipalDocument}
          onChange={e => {
            setStore({
              ...store,
              documents: {
                ...store.documents,
                municipalDocument: e.target.value
              }
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>CEP</FormLabel>
        <Paper className={classes.zipPaper}>
          <NumberFormat
            id="zipCode"
            name="zipCode"
            className={classes.textField}
            customInput={TextField}
            format={"#####-###"}
            value={store && store.address.zipCode}
            onChange={e => {
              setStore({
                ...store,
                address: { ...store.address, zipCode: e.target.value }
              });
            }}
          />
          <Divider className={classes.divider} />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => searchZipCode()}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Endereço</FormLabel>
        <TextField
          id="address"
          name="address"
          className={classes.textField}
          value={store && store.address.address}
          onChange={e => {
            setStore({
              ...store,
              address: { ...store.address, address: e.target.value }
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Número</FormLabel>
        <TextField
          id="number"
          name="number"
          className={classes.textField}
          value={store && store.address.number}
          onChange={e => {
            setStore({
              ...store,
              address: { ...store.address, number: e.target.value }
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Bairro</FormLabel>
        <TextField
          id="block"
          name="block"
          className={classes.textField}
          value={store && store.address.block}
          onChange={e => {
            setStore({
              ...store,
              address: { ...store.address, block: e.target.value }
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Cidade</FormLabel>
        <TextField
          id="city"
          name="city"
          className={classes.textField}
          value={store && store.address.city}
          onChange={e => {
            setStore({
              ...store,
              address: { ...store.address, city: e.target.value }
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Estado</FormLabel>
        <TextField
          id="state"
          name="state"
          className={classes.textField}
          value={store && store.address.state}
          onChange={e => {
            setStore({
              ...store,
              address: { ...store.address, setState: e.target.value }
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Tipos de Frete</FormLabel>
        <Select
          native
          value={store && store.freightType.freight._id}
          inputProps={{
            name: "freight",
            id: "freight"
          }}
          onChange={e => {
            console.log(e.target.value);
            getFreight(e.target.value);
            setStore({
              ...store,
              freightType: { ...store.freightType, freight: e.target.value }
            });
          }}
        >
          <option value="">Selecione um tipo de frete</option>
          {freights.map(f => (
            <option key={f._id} value={f._id}>
              {f.name}
            </option>
          ))}
        </Select>
      </FormGroup>
      {freight && freight.identifier === "FIXO" && (
        <FormGroup>
          <FormLabel className={classes.formboxLabel}>Valor do Frete</FormLabel>
          <NumberFormat
            disabled={store && store.freightType.value === "" ? true : false}
            id="freightValue"
            name="freightValue"
            className={classes.textField}
            customInput={TextField}
            value={store && store.freightType.value}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={values => {
              setStore({
                ...store,
                freightType: { ...store.freightType, value: values.floatValue }
              });
            }}
          />
        </FormGroup>
      )}
      {freight && freight.identifier === "CORREIOS" && (
        <>
          <FormGroup>
            <FormLabel className={classes.formboxLabel}>
              Tipos de Postagem
            </FormLabel>
            <Select
              native
              value={store && store.freightType.correiosPostingType}
              inputProps={{
                name: "correiosPostingType",
                id: "correiosPostingType"
              }}
              onChange={e => {
                setStore({
                  ...store,
                  freightType: {
                    ...store.freightType,
                    correiosPostingType: e.target.value
                  }
                });
              }}
            >
              <option value="">
                Selecione um tipo de Postagem dos Correios
              </option>
              {correiosPostingTypes.map(c => (
                <option key={c._id} value={c._id}>
                  {c.identifier} - {c.name}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <FormLabel className={classes.formboxLabel}>
              Formato da embalagem
            </FormLabel>
            <Select
              native
              value={store && store.freightType.correiosPostingFormat}
              inputProps={{
                name: "correiosPostingFormat",
                id: "correiosPostingFormat"
              }}
              onChange={e => {
                setStore({
                  ...store,
                  freightType: {
                    ...store.freightType,
                    correiosPostingFormat: e.target.value
                  }
                });
              }}
            >
              <option value="">Selecione o formato da embalagem</option>
              <option key={1} value={1}>
                Caixa / Pacote
              </option>
              <option key={2} value={2}>
                Rolo / Prisma
              </option>
              <option key={3} value={3}>
                Envelope
              </option>
            </Select>
          </FormGroup>
          <FormGroup>
            <FormLabel className={classes.formboxLabel}>
              Serviço Adicional Mão Própria
            </FormLabel>
            <Select
              native
              value={store && store.freightType.correiosAditionalOwnerHand}
              inputProps={{
                name: "correiosAditionalOwnerHand",
                id: "correiosAditionalOwnerHand"
              }}
              onChange={e => {
                setStore({
                  ...store,
                  freightType: {
                    ...store.freightType,
                    correiosAditionalOwnerHand: e.target.value
                  }
                });
              }}
            >
              <option value="">Selecione Sim ou Não</option>
              <option key={1} value={"S"}>
                Sim
              </option>
              <option key={2} value={"N"}>
                Não
              </option>
            </Select>
          </FormGroup>
          <FormGroup>
            <FormLabel className={classes.formboxLabel}>
              Valor Declarado
            </FormLabel>
            <Select
              native
              value={store && store.freightType.correiosDeclaredValue}
              inputProps={{
                name: "correiosDeclaredValue",
                id: "correiosDeclaredValue"
              }}
              onChange={e => {
                setStore({
                  ...store,
                  freightType: {
                    ...store.freightType,
                    correiosDeclaredValue: e.target.value
                  }
                });
              }}
            >
              <option value="">Selecione Sim ou Não</option>
              <option key={1} value={"S"}>
                Sim
              </option>
              <option key={2} value={"N"}>
                Não
              </option>
            </Select>
          </FormGroup>
          <FormGroup>
            <FormLabel className={classes.formboxLabel}>
              Aviso de Recebimento
            </FormLabel>
            <Select
              native
              value={store && store.freightType.correiosReceivedWarning}
              inputProps={{
                name: "correiosReceivedWarning",
                id: "correiosReceivedWarning"
              }}
              onChange={e => {
                setStore({
                  ...store,
                  freightType: {
                    ...store.freightType,
                    correiosReceivedWarning: e.target.value
                  }
                });
              }}
            >
              <option value="">Selecione Sim ou Não</option>
              <option key={1} value={"S"}>
                Sim
              </option>
              <option key={2} value={"N"}>
                Não
              </option>
            </Select>
          </FormGroup>
        </>
      )}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="add"
        className={classes.margin}
        onClick={() => cancel()}
      >
        Cancelar
      </Fab>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.margin}
        onClick={() => save()}
      >
        Gravar
      </Fab>
    </div>
  );
}
