import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Toastr from "../../components/Toastr";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  button: {
    margin: theme.spacing(1)
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  margin: {
    marginTop: 15
  }
}));

export default function FilterOrder(props) {
  const classes = useStyles();
  const [message, setMessage] = React.useState(null);

  const [orderstatuses, setOrderStatuses] = React.useState([]);

  const [orderId, setOrderId] = React.useState("");
  const [partnerOrderId, setPartnerOrderId] = React.useState("");
  const [federalDocument, setFederalDocument] = React.useState("");
  const [orderStatusId, setOrderStatusId] = React.useState("");

  const onCloseToastr = () => {
    setMessage(null);
  };

  const filter = () => {
    let bodyFilter = {};
    if (orderId) {
      bodyFilter._id = orderId;
    }
    if (partnerOrderId) {
      bodyFilter.partnerOrderID = partnerOrderId;
    }
    if (federalDocument) {
      bodyFilter = {
        ...bodyFilter,
        "deliveryDetail.federalDocument": federalDocument
          .replace(/\./g, "")
          .replace(/\-/g, "")
      };
    }
    if (orderStatusId) {
      bodyFilter.status = {};
      bodyFilter.status._id = orderStatusId;
    }
    props.onFilter(bodyFilter);
  };

  React.useEffect(() => {
    setOrderStatuses(props.orderstatuses);
  }, [props.orderstatuses]);

  return (
    <div className={classes.formbox}>
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <Typography variant="h5" className={classes.formboxTitle}>
        Filtrar Pedidos
      </Typography>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>ID Pedido</FormLabel>
        <TextField
          id="orderId"
          name="orderId"
          className={classes.textField}
          value={orderId}
          onChange={e => setOrderId(e.target.value)}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Pedido Parceiro</FormLabel>
        <TextField
          id="partnerOrderId"
          name="partnerOrderId"
          className={classes.textField}
          value={partnerOrderId}
          onChange={e => setPartnerOrderId(e.target.value)}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>CPF</FormLabel>
        <NumberFormat
          id="federalDocument"
          name="federalDocument"
          className={classes.textField}
          customInput={TextField}
          format={"###.###.###-##"}
          value={federalDocument}
          onChange={e => {
            setFederalDocument(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Status do Pedido</FormLabel>
        <Select
          value={orderStatusId}
          inputProps={{
            name: "orderStatus",
            id: "orderStatus"
          }}
          onChange={e => setOrderStatusId(e.target.value)}
        >
          <MenuItem value={""}>Selecione um status de pedido</MenuItem>
          {orderstatuses.map(o => (
            <MenuItem value={o._id} key={o._id}>
              {o.name}
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => filter()}
      >
        Filtrar
        <DoneAllIcon />
      </Button>
    </div>
  );
}
