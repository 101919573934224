import React from "react";
import { Typography, Card, CardContent, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  button: {
    margin: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

export default function ListCDs(props) {
  const classes = useStyles();

  const [cds, setCds] = React.useState([]);

  React.useEffect(() => {
    setCds(props.cds);
  }, [props.cds]);

  const editCd = _id => {
    props.onEdit(_id);
  };
  
  const handleChange = index => event => {
    props.onUpdate(index);
  };

  return (
    <>
      {cds.length === 0 && (
        <Card>
          <CardContent>
            Não há centros de distribuição cadastrados ou <br />
            <br />
            <b>
              Não existe uma loja ativa, clique <Link href="/store">aqui</Link>
              {" para ativar uma loja "}
            </b>
            !
          </CardContent>
        </Card>
      )}
      {cds.length > 0 && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Identificador</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>{}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cds.map((row, index) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.identifier}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>                  
                  <TableCell>
                    <IconButton
                      onClick={() => editCd(row._id)}
                      className={classes.button}
                      aria-label="editar"
                    >
                      <EditIcon />
                    </IconButton>                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </>
  );
}
