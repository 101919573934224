import React from "react";
import Internal from "../../layouts/Internal";
import OauthService from "../../services/oauthservice";
import OrderService from "../../services/orderservice";
import OrderList from "./list";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import FilterListIcon from "@material-ui/icons/FilterList";
import DescriptionIcon from "@material-ui/icons/Description";
import BackspaceIcon from "@material-ui/icons/Backspace";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { capitalize } from "@material-ui/core/utils";
import TrackingService from "../../services/trackingservice";
import OrderStatusService from "../../services/orderstatusservice";
import AddTracking from "./addTracking";
import FilterOrder from "./filterOrder";
import Toastr from "../../components/Toastr";
import { Link } from "@material-ui/core";
import AddTrackingCode from "./addTrackingCode";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  speedDial: {
    position: "fixed",
    bottom: "50px",
    right: "50px",
    "&$directionUp, &$directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(3)
    },
    "&$directionDown, &$directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(3)
    }
  },
  directionUp: {},
  directionRight: {},
  directionDown: {},
  directionLeft: {},
  formswipeable: {
    width: "85%"
  }
}));

const actions = [
  { icon: <DescriptionIcon />, name: "Exportar", action: "export" },
  { icon: <FilterListIcon />, name: "Filtrar", action: "filter" },
  { icon: <BackspaceIcon />, name: "Limpar Filtro", action: "cleanfilter" }
];

export default function Order(props) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [orders, setOrders] = React.useState([]);
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);
  const [trackings, setTrackings] = React.useState([]);
  const [orderstatuses, setOrderStatuses] = React.useState([]);
  const [orderId, setOrderId] = React.useState("");
  const [order,setOrder] = React.useState(null);

  const [formTracking, setFormTracking] = React.useState(false);
  const [formFilter, setFormFilter] = React.useState(false);
  const [formTrackingCode, setFormTrackingCode] = React.useState(false);

  React.useEffect(() => {
    setProgress(true);
    loadOrders();
    loadTrackings();
    loadOrderStatuses();
    setProgress(false);
  }, []);

  const speedDialClassName = clsx(
    classes.speedDial,
    classes[`direction${capitalize(direction)}`]
  );

  const toggleTracking = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFormTracking(open);
  };

  const toggleTrackingCode = (side,open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFormTrackingCode(open);
  }

  const toggleFilter = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFormFilter(open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = action => {
    switch (action.action) {
      case "export":
        exportOrders();
        break;
      case "filter":
        setFormFilter(true);
        break;
      case "cleanfilter":
        loadOrders();
        break;
      default:
        break;
    }

    setOpen(prevOpen => !prevOpen);
  };

  const exportOrders = async () => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new OrderService(
        token.data.access_token
      ).exportByStore();
      const filename = result.data.value;
      const msg = `Clique <a style='color:#ffffff' href=${filename} alt=''>aqui</a> para baixar o arquivo!`;
      setMessage(msg);
    } catch (e) {
      console.log(e);
      setMessage("Erro ao gerar o relatório de pedidos!");
    }
  };

  const loadOrders = async body => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new OrderService(
        token.data.access_token
      ).listOrdersByStore(body);
      setOrders(result.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const loadTrackings = async () => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new TrackingService(token.data.access_token).list();
      setTrackings(result.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const loadOrderStatuses = async () => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new OrderStatusService(token.data.access_token).list();
      setOrderStatuses(result.data.value);
    } catch (e) {
      console.log(e);
    }
  };

  const toggleFormTracking = _id => {
    setOrderId(_id);
    setFormTracking(!formTracking);
  };

  const toggleFormTrackingCode = order => {
    setOrder(order);
    setOrderId(order._id);
    setFormTrackingCode(!formTrackingCode);
  }

  const addTrackingToOrder = async (orderId, trackingId) => {
    try {
      setProgress(true);
      let token = await new OauthService().gettoken();
      let result = await new OrderService(token.data.access_token).addTracking(
        orderId,
        trackingId
      );
      setFormTracking(!formTracking);
      loadOrders();
      setProgress(false);
    } catch (e) {
      setProgress(false);
      console.log(e);
    }
  };

  const addTrackingCodeToOrder = async(order,trackingCode) => {
    try {
      setProgress(true);
      const orderId = order._id;
      const partnerOrderId = order.partnerOrderID;
      let token = await new OauthService().gettoken();
      let result = await new OrderService(token.data.access_token).addTrackingCode(
        orderId,
        partnerOrderId,
        trackingCode
      );
      setFormTrackingCode(!formTrackingCode);
      loadOrders();
      setProgress(false);
    } catch (e) {
      setProgress(false);
      console.log(e);
    }
  }

  const filter = async bodyFilter => {
    loadOrders(bodyFilter);
    setFormFilter(false);
  };

  const confirmOrder = async _id => {
    try {
      setProgress(true);
      let token = await new OauthService().gettoken();
      let result = await new OrderService(token.data.access_token).confirmOrder(
        _id
      );
      loadOrders();
      setProgress(false);
    } catch (e) {
      console.log(e);
      setProgress(false);
    }
  };

  const cancelOrder = async _id => {
    try {
      setProgress(true);
      let token = await new OauthService().gettoken();
      let result = await new OrderService(token.data.access_token).cancelOrder(
        _id
      );
      loadOrders();
      setProgress(false);
    } catch (e) {
      console.log(e);
      setProgress(false);
    }
  };

  const onCloseToastr = () => {
    setMessage(null);
  };

  return (
    <Internal
      {...props}
      title="Pedidos"
      progress={progress}
      permission={["ADMIN", "STOREADMIN", "LOGISTIC"]}
    >
      <Toastr message={message} onClose={() => onCloseToastr()} />
      {orders && orders.length === 0 && (
        <Card>
          <CardContent>
            Não há pedidos cadastrados ou <br />
            <br />
            <b>
              Não existe uma loja ativa, clique <Link href="/store">aqui</Link>
              {" para ativar uma loja "}
            </b>
            !
          </CardContent>
        </Card>
      )}

      <SwipeableDrawer
        anchor="right"
        open={formTrackingCode}
        onClose={toggleTrackingCode(false)}
        onOpen={toggleTrackingCode(true)}
        classes={{
          paper: classes.formswipeable
        }}
      >
        <AddTrackingCode        
          onSave={(order, trackingCode) =>
            addTrackingCodeToOrder(order, trackingCode)
          }
          order={order}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={formTracking}
        onClose={toggleTracking(false)}
        onOpen={toggleTracking(true)}
        classes={{
          paper: classes.formswipeable
        }}
      >
        <AddTracking
          trackings={trackings}
          onSave={(orderId, trackingId) =>
            addTrackingToOrder(orderId, trackingId)
          }
          orderId={orderId}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={formFilter}
        onClose={toggleFilter(false)}
        onOpen={toggleFilter(true)}
        classes={{
          paper: classes.formswipeable
        }}
      >
        <FilterOrder
          orderstatuses={orderstatuses}
          onFilter={bodyFilter => filter(bodyFilter)}
        />
      </SwipeableDrawer>

      {orders.length > 0 && (
        <OrderList
          orders={orders}
          toggleFormTracking={_id => toggleFormTracking(_id)}
          toggleFormTrackingCode={order => toggleFormTrackingCode(order)}
          onConfirm={_id => confirmOrder(_id)}
          onCancel={_id => cancelOrder(_id)}
        />
      )}

      <SpeedDial
        ariaLabel="SpeedDial example"
        className={speedDialClassName}
        hidden={false}
        icon={<SpeedDialIcon />}
        onBlur={handleClose}
        onClick={handleClick}
        onClose={handleClose}
        onFocus={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        open={open}
        direction={direction}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action)}
          />
        ))}
      </SpeedDial>
    </Internal>
  );
}
