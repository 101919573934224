import React from "react";
import PropTypes from "prop-types";
import config from "../../config";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Fab from "@material-ui/core/Fab";

import EanMaskText from "../../components/EanMaskText";
import NumberField from "../../components/NumberField";
import Toastr from "../../components/Toastr";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  imageBox: {
    width: "250px",
    height: "250px",
    border: "1px dashed rgb(195, 195, 195)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

EanMaskText.propTypes = {
  inputRef: PropTypes.func.isRequired
};

NumberField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function AddCD(props) {
  const classes = useStyles();

  const [cd, setCd] = React.useState({});
  const [message, setMessage] = React.useState(null);
  

  React.useEffect(() => {
    setCd(props.cd);
    if (props.cd) {
    }
  }, [props.cd]);
  
  const changeValue = e => {
    const propname = e.target.name;
    const propvalue = e.target.value;
    setCd({ ...cd, [propname]: propvalue });
  };  

  const save = () => {
    props.onSave(cd);
  };

  const cancel = () => {
    props.onCancel();
  };
    

  const onCloseToastr = () => {
    setMessage(null);
  };

  return (
    <div className={classes.formbox}>      
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <Typography variant="h5" className={classes.formboxTitle}>
        Cadastro de Centro de Distribuição
      </Typography>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Identificador (exemplo, CNPJ do Centro, se usar o CNPJ só inclua os números)</FormLabel>
        <TextField
          id="identifier"
          name="identifier"
          className={classes.textField}
          value={cd.identifier}
          onChange={e => changeValue(e)}
          margin="normal"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>Nome</FormLabel>
        <Input
          value={cd.name}
          onChange={e => changeValue(e)}
          id="name"
          name="name"
          className={classes.textField}
        />
      </FormGroup>      
      <Fab
        variant="extended"
        color="secondary"
        aria-label="add"
        className={classes.margin}
        onClick={() => cancel()}
      >
        Cancelar
      </Fab>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.margin}
        onClick={() => save()}
      >
        Gravar
      </Fab>
    </div>
  );
}
