import React from "react";
import { Input, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Toastr from "../../components/Toastr";
import { Save } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  button: {
    margin: theme.spacing(1)
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  margin: {
    marginTop: 15
  }
}));

export default function AddTrackingCode(props) {
  const classes = useStyles();
  const [message, setMessage] = React.useState(null);
  const [orderId, setOrderId] = React.useState("");
  const [order,setOrder] = React.useState(null);
  const [trackingCode, setTrackingCode] = React.useState("");

  React.useEffect(() => {
    setOrder(props.order && props.order);
    setTrackingCode(props.order && props.order.trackingCode);
  }, [props.order]);

  const addTrackingCode = () => {
    if (trackingCode === ""){
      setMessage("Fornecer o código de rastreio");
    } else {
      props.onSave(order, trackingCode);
    }
  };

  const onCloseToastr = () => {
    setMessage(null);
  };

  const changeValue = e => {
    setTrackingCode(e.target.value);
  };  


  return (
    <div className={classes.formbox}>
      <Toastr message={message} onClose={() => onCloseToastr()} />
      <Typography variant="h5" className={classes.formboxTitle}>
        Adicionar Código de Rastreio
      </Typography>
      <FormGroup>
        <FormLabel className={classes.formboxLabel}>
          Digite o Código
        </FormLabel>
        <Input
          value={trackingCode}
          onChange={e => changeValue(e)}
          id="trackingCode"
          name="trackingCode"
          className={classes.textField}
        />
      </FormGroup>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => addTrackingCode()}
      >
        <Save/>
        <span>Adicionar</span>
      </Button>
    </div>
  );
}
