import React from "react";
import config from "../../config";
import clsx from "clsx";
import Internal from "../../layouts/Internal";
import OauthService from "../../services/oauthservice";
import CdService from "../../services/cdservice";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { capitalize } from "@material-ui/core/utils";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import AddCDIcon from "@material-ui/icons/Add";

import AddCD from "./addCD";
import ListCDs from "./listCds";

import GeneralException from "../../exception/GeneralException";
import Toastr from "../../components/Toastr";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  speedDial: {
    position: "fixed",
    "&$directionUp, &$directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(3)
    },
    "&$directionDown, &$directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(3)
    }
  },
  directionUp: {},
  directionRight: {},
  directionDown: {},
  directionLeft: {},
  formswipeable: {
    width: "85%"
  },
  formsimages: {
    width: "90%",
    height: "90%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) !important"
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

const actions = [
  { icon: <AddCDIcon />, name: "Centro de Distribuição", action: "one" }
];

export default function DistributionCenters(props) {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [cds, setCds] = React.useState([]);
  const [cd, setCd] = React.useState({});
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);
  const [cdinsert, setCdinsert] = React.useState(false);

  const toggleCdDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setCdinsert(open);
  };
    

  const speedDialClassName = clsx(
    classes.speedDial,
    classes[`direction${capitalize(direction)}`]
  );

  React.useEffect(() => {
    loadCdsByStore();
  }, []);

  const loadCdsByStore = async () => {
    try {
      setProgress(true);
      let token = await new OauthService().gettoken();
      let cds = await new CdService(token.data.access_token).list();
      setCds(cds.data.value);
      setProgress(false);
    } catch (e) {
      setProgress(false);
      console.log(e);
    }
  };

  const insertOne = () => {
    setCdinsert(true);
  };

  const handleClick = action => {
    switch (action.action) {
      case "one":
        const cd = {
          identifier:"",
          name:"",          
          store: localStorage.getItem(config.STORAGE_NAME + "_store")
        };
        setCd(cd);
        insertOne();
        break;
      default:
        break;
    }

    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onEditCd = async _id => {
    try {
      let token = await new OauthService().gettoken();
      let cd = await new CdService(token.data.access_token).get(_id);
      setCd(cd.data.value);
      insertOne();
    } catch (e) {
      console.log(e);
    }
  };

  const onAddCdCancel = () => {
    setCdinsert(false);
  };

  const onAddCdSave = async cd => {
    let cdSave = null;
    if (cd._id) {
      cdSave = { ...cd, updated_at: new Date().toISOString() };
    } else {
      cdSave = {
        ...cd,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };
    }
    try {
      let token = await new OauthService().gettoken();
      let cd = await new CdService(token.data.access_token)
        .save(cdSave)
        .catch(e => {
          throw new GeneralException(e);
        });
      setCdinsert(false);
      await loadCdsByStore();
    } catch (e) {
      if (e.exception) {
        setErrorMessage(e.exception.response.data.value);
      }
    }
  };

  const onCloseToastr = () => {
    setErrorMessage(null);
  };

  const onUpdateCds = async index => {
    try {
      let token = await new OauthService().gettoken();
      let cd = await new CdService(token.data.access_token)
        .toggle(cds[index]._id)
        .catch(e => {
          throw new GeneralException(e);
        });
      await loadCdsByStore();
    } catch (e) {
      console.log(e);
    }
  };  

  return (
    <Internal
      {...props}
      progress={progress}
      title="Gestão de Centros de Distribuição"
      permission={["ADMIN", "STOREADMIN"]}
    >
      <Toastr message={errorMessage} onClose={() => onCloseToastr()} />
      <SwipeableDrawer
        anchor="right"
        open={cdinsert}
        onClose={toggleCdDrawer(false)}
        onOpen={toggleCdDrawer(true)}
        classes={{
          paper: classes.formswipeable
        }}
      >
        <AddCD
          cd={cd}
          onSave={cd => onAddCdSave(cd)}
          onCancel={() => onAddCdCancel()}
        />
      </SwipeableDrawer>            

      <ListCDs
        cds={cds}
        onEdit={_id => onEditCd(_id)}
        onUpdate={index => onUpdateCds(index)}
      />

      <SpeedDial
        ariaLabel="SpeedDial example"
        className={speedDialClassName}
        hidden={false}
        icon={<SpeedDialIcon />}
        onBlur={handleClose}
        onClick={handleClick}
        onClose={handleClose}
        onFocus={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        open={open}
        direction={direction}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action)}
          />
        ))}
      </SpeedDial>
    </Internal>
  );
}
