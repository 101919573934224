import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  button: {
    margin: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

export default function ListUsers(props) {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  const editUser = id => {
    props.onEdit(id);
  };

  const removeUser = id => {
    if (window.confirm("Deseja realmente excluir este usuário?")) {
      props.onRemove(id);
    }
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>E-mail</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>{}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(u => (
            <TableRow key={u._id}>
              <TableCell component="th" scope="row">
                {u.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {u.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {u.usertype && u.usertype.name}
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => editUser(u._id)}
                  className={classes.button}
                  aria-label="editar"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => removeUser(u._id)}
                  className={classes.button}
                  arial-label="remover"
                >
                  <DeleteForeverIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
