import React from "react";
import config from "../../config";
import clsx from "clsx";
import Internal from "../../layouts/Internal";
import OauthService from "../../services/oauthservice";
import ProductService from "../../services/productservice";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { capitalize } from "@material-ui/core/utils";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import AddProductIcon from "@material-ui/icons/Add";
import BatchProductIcon from "@material-ui/icons/PlaylistAdd";

import AddProduct from "./addProduct";
import ListProduct from "./listProduct";
import BatchProduct from "./batchProduct";

import GeneralException from "../../exception/GeneralException";
import Toastr from "../../components/Toastr";
import ImagesProducts from "./imagesProduct";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  speedDial: {
    position: "fixed",
    "&$directionUp, &$directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(3)
    },
    "&$directionDown, &$directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(3)
    }
  },
  directionUp: {},
  directionRight: {},
  directionDown: {},
  directionLeft: {},
  formswipeable: {
    width: "85%"
  },
  formsimages: {
    width: "90%",
    height: "90%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) !important"
  },
  formbox: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  formboxTitle: {
    textAlign: "center"
  },
  formboxMessage: {
    textAlign: "center",
    marginTop: 30
  },
  formboxLabel: {
    marginTop: 10,
    marginBottom: 10
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: 15
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

const actions = [
  { icon: <BatchProductIcon />, name: "Lote de Produtos", action: "batch" },
  { icon: <AddProductIcon />, name: "Um Produto", action: "one" }
];

export default function Products(props) {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [product, setProduct] = React.useState({});
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);
  const [productinsert, setProductinsert] = React.useState(false);
  const [productbatch, setProductbatch] = React.useState(false);
  const [productImages, setProductImages] = React.useState(false);

  const toggleProductDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setProductinsert(open);
  };

  const toggleProductBatchDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setProductbatch(open);
  };

  const toggleProductImages = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setProductImages(open);
  };

  const speedDialClassName = clsx(
    classes.speedDial,
    classes[`direction${capitalize(direction)}`]
  );

  React.useEffect(() => {
    loadProductsByStore();
  }, []);

  const loadProductsByStore = async () => {
    try {
      setProgress(true);
      let token = await new OauthService().gettoken();
      let products = await new ProductService(token.data.access_token).list();
      setProducts(products.data.value);
      setProgress(false);
    } catch (e) {
      setProgress(false);
      console.log(e);
    }
  };

  const insertOne = () => {
    setProductinsert(true);
  };

  const insertBatch = () => {
    setProductbatch(true);
  };

  const handleClick = action => {
    switch (action.action) {
      case "batch":
        insertBatch();
        break;
      case "one":
        const newproduct = {
          active: true,
          brand: "",
          discount: 0,
          ean: "",
          firstCategory: "",
          height: 0,
          images: [],
          length: 0,
          listPrice: 0,
          longDescription: "",
          salePrice: 0,
          secondCategory: "",
          shortDescription: "",
          sku: "",
          specification: "",
          stock: { hasStock: false, minimum: 0, available: 0 },
          store: localStorage.getItem(config.STORAGE_NAME + "_store"),
          weight: 0,
          width: 0
        };
        setProduct(newproduct);
        insertOne();
        break;
      default:
        break;
    }

    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onEditProduct = async _id => {
    try {
      let token = await new OauthService().gettoken();
      let product = await new ProductService(token.data.access_token).get(_id);
      setProduct(product.data.value);
      insertOne();
    } catch (e) {
      console.log(e);
    }
  };

  const onAddProductCancel = () => {
    setProductinsert(false);
  };

  const onAddProductSave = async product => {
    let productSave = null;
    if (product._id) {
      productSave = { ...product, updated_at: new Date().toISOString() };
    } else {
      productSave = {
        ...product,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };
    }
    try {
      let token = await new OauthService().gettoken();
      let product = await new ProductService(token.data.access_token)
        .save(productSave)
        .catch(e => {
          throw new GeneralException(e);
        });
      setProductinsert(false);
      await loadProductsByStore();
    } catch (e) {
      if (e.exception) {
        setErrorMessage(e.exception.response.data.value);
      }
    }
  };

  const onCloseToastr = () => {
    setErrorMessage(null);
  };

  const onUpdateProducts = async index => {
    try {
      let token = await new OauthService().gettoken();
      let product = await new ProductService(token.data.access_token)
        .toggle(products[index]._id)
        .catch(e => {
          throw new GeneralException(e);
        });
      await loadProductsByStore();
    } catch (e) {
      console.log(e);
    }
  };

  const onImageProduct = async _id => {
    try {
      let token = await new OauthService().gettoken();
      let product = await new ProductService(token.data.access_token).get(_id);
      setProduct(product.data.value);
      setProductImages(true);
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateImageList = async (_id, images) => {
    try {
      let token = await new OauthService().gettoken();
      let result = await new ProductService(token.data.access_token).get(_id);
      const product = result.data.value;
      product.images = images;
      setProduct(product);
      token = await new OauthService().gettoken();
      result = await new ProductService(token.data.access_token)
        .save(product)
        .catch(e => {
          throw new GeneralException(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Internal
      {...props}
      progress={progress}
      title="Gestão de Produtos"
      permission={["ADMIN", "STOREADMIN"]}
    >
      <Toastr message={errorMessage} onClose={() => onCloseToastr()} />
      <SwipeableDrawer
        anchor="right"
        open={productinsert}
        onClose={toggleProductDrawer(false)}
        onOpen={toggleProductDrawer(true)}
        classes={{
          paper: classes.formswipeable
        }}
      >
        <AddProduct
          product={product}
          onSave={product => onAddProductSave(product)}
          onCancel={() => onAddProductCancel()}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={productbatch}
        onClose={toggleProductBatchDrawer(false)}
        onOpen={toggleProductBatchDrawer(true)}
        classes={{
          paper: classes.formswipeable
        }}
      >
        <BatchProduct />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={productImages}
        onClose={toggleProductImages(false)}
        onOpen={toggleProductImages(true)}
        classes={{
          paper: classes.formsimages
        }}
      >
        <ImagesProducts
          product={product}
          onUpdateImageList={(_id, images) => onUpdateImageList(_id, images)}
        />
      </SwipeableDrawer>

      <ListProduct
        products={products}
        onEdit={_id => onEditProduct(_id)}
        onUpdate={index => onUpdateProducts(index)}
        onImages={_id => onImageProduct(_id)}
      />

      <SpeedDial
        ariaLabel="SpeedDial example"
        className={speedDialClassName}
        hidden={false}
        icon={<SpeedDialIcon />}
        onBlur={handleClose}
        onClick={handleClick}
        onClose={handleClose}
        onFocus={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        open={open}
        direction={direction}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action)}
          />
        ))}
      </SpeedDial>
    </Internal>
  );
}
